.rdt_Table {
  border: rgba(0, 0, 0, .12) solid 1px;
  margin-top: 10px;

}

.rdt_TableHeadRow {
  background-color: #eeeeee !important;
  font-size: 14px;
  font-weight: bold;
  min-height: 35px !important;
}

.rdt_TableCol {
  border-right: rgba(0, 0, 0, .12) solid 1px;

  &:last-child {
    border-right: none;
  }
}

.rdt_TableCell {
  border-right: rgba(0, 0, 0, .12) solid 1px;
  font-size: 14px;

  &:last-child {
    border-right: none;
  }
}