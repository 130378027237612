
.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.Mui-selected {
  background-color: #D04437 !important;
}

.MuiPickersCalendarHeader-label {
  text-transform: capitalize;
}
