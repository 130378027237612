#sidebar {
  width: 70px;
  min-width: 70px;
  z-index: 1000;
  transition: all .25s ease-in-out;
  background-color: #A5A7A0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);

  &.expand {
    width: 260px;
    min-width: 260px;
  }
}

.toggle-btn {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 1rem 1.5rem;

  > svg {
    font-size: 2rem;
    color: #FFF;
  }
}

.sidebar-logo {
  margin: auto 0;

  > span {
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;
  }
}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
  display: none;
}

.sidebar-nav {
  padding: 2rem 0;
  flex: 1 1 auto;
}

a.sidebar-link {
  border-left: 3px solid transparent;
  color: #FFF;
  display: block;
  font-size: 0.9rem;
  padding: .625rem 1.625rem;
  text-decoration: none;
  white-space: nowrap;
}

.sidebar-link svg {
  font-size: 1.4rem;
  margin-right: .75rem;
}

a.sidebar-link:hover {
  background-color: rgba(255, 255, 255, .075);
  border-left: 3px solid #3b7ddd;
}

.sidebar-dropdown {
  padding-left: 1rem;
}

.sidebar-item {
  position: relative;
}

#sidebar &:not(.expand) .sidebar-dropdown .sidebar-item {
  border-top: 1px solid #FFF;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
  position: absolute;
  top: 0;
  left: 70px;
  background-color: #A5A7A0;
  padding: 0;
  min-width: 15rem;
  display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
  display: block;
  width: 100%;
  opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 .075rem .075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.1rem;
  transform: rotate(-135deg);
  transition: all .2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all .2s ease-out;
}